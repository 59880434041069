/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import CustomLink from 'components/shared/CustomLink'
import VacatureInfo from 'components/vacatures/VacatureInfo'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledCustomLink = styled(CustomLink)``

const ContentContainer = styled.div`
  ${props => css`
    font-size: ${props.theme.font.size.m};
    color: ${props.theme.color.text.secondary};
    font-weight: ${props.theme.font.weight.s};
  `}

  & h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    margin-bottom: 0;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressWpVacature(wordpress_id: { eq: $wordpress_id }) {
      ...VacatureFrag
    }
  }
`

const VacatureDetailTemplate = ({
  data: {
    page: {
      path,
      yoastMeta,
      acf: {
        banner,
        info,
        content
      }
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Hero detail className="mb-3" content={banner} />
      <div className="container pb-5">
        <div className="row">
          <div className="col-lg-8">
            <StyledCustomLink to="/werken-bij-voor-ssb">
              <p className="text-uppercase font-weight-xl color-text-gray">{`< Terug naar het overzicht`}</p>
            </StyledCustomLink>
            <ContentContainer className="pr-lg-5 pt-lg-3">
              {parse(content.text)}
            </ContentContainer>
          </div>
          <div className="col-lg-4 d-flex justify-content-lg-end justify-content-center">
            <div>
              <VacatureInfo info={info} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VacatureDetailTemplate
